import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import firebase, { getPublicUrl } from 'src/lib/firebase';
import { now } from 'src/utils/date';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  list: [],
  selected: {
    isLoaded: false,
    game: null,
  },
  isLoading: false,
  error: null,
};

export const GetGames = createAsyncThunk('post/getGames', async () => {
  const gamesRef = await firebase
    .firestore()
    .collection('games')
    .orderBy('createdAt', 'desc')
    .get();

  return await Promise.all(
    gamesRef.docs.map(async doc => {
      const data = doc.data();
      const logoUrl = await getPublicUrl(data.logo);
      return _.assign({ id: doc.id }, data, { logo: logoUrl });
    }),
  );
});

export const GetGame = createAsyncThunk('post/getGame', async gameId => {
  const gameRef = await firebase
    .firestore()
    .collection('games')
    .doc(gameId)
    .get();

  if (gameRef.exists) {
    return _.assign(gameRef.data(), { id: gameRef.id });
  } else {
    throw new Error('Game not found');
  }
});

export const CreateGame = props => {
  return firebase
    .firestore()
    .collection('games')
    .add({
      integrationToken: uuidv4(),
      status: true,
      createdAt: now(),
      ...props,
    });
};

export const UpdateGame = (gameId, gameProps) => async dispatch => {
  await firebase
    .firestore()
    .collection('games')
    .doc(gameId)
    .update(gameProps);
};

export const RemoveGame = gameId => async dispatch => {
  await firebase
    .firestore()
    .collection('games')
    .doc(gameId)
    .delete();
};

const slice = createSlice({
  name: 'game',
  initialState,
  extraReducers: {
    [GetGames.fulfilled]: (state, action) => {
      state.list = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    [GetGames.pending]: (state, action) => {
      state.list = [];
      state.isLoading = true;
      state.error = null;
    },
    [GetGames.rejected]: (state, action) => {
      state.list = [];
      state.error = action.error;
      state.isLoading = false;
    },
    [GetGame.fulfilled]: (state, action) => {
      state.selected.isLoaded = true;
      state.selected.game = action.payload;
      state.error = null;
    },
    [GetGame.pending]: (state, action) => {
      state.selected.isLoaded = false;
      state.selected.game = null;
      state.error = null;
    },
    [GetGame.rejected]: (state, action) => {
      state.selected.isLoaded = false;
      state.selected.game = null;
      state.error = action.error;
    },
  },
});

export const reducer = slice.reducer;
