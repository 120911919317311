export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
};

export const COMPANY = {
  ADMINS: [
    'umit.dincel@gamester.com.tr',
    'volkan.taban@gamester.com.tr',
    'onur.karci@gamester.com.tr',
    'burak.engin@gamester.com.tr',
  ],
  emailCheck: {
    regex: /^[A-Za-z0-9._%+-]+@gamester.com.tr$/,
    text: 'Only company emails',
  },
};

export const API = {
  URL:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:8080/'
      : 'https://europe-west3-helpster-7cde8.cloudfunctions.net/api',
};

export const TAGS = [
  { name: 'Teknik Sorun', key: 'technical-issue' },
  { name: 'Abonelik Aktif Etme', key: 'subscription-activation' },
  { name: 'Abonelik İptal & İade', key: 'subscription-cancellation' },
  { name: 'Bilgi Alma', key: 'need-information' },
  { name: 'İşbirliği', key: 'cooperation' },
  { name: 'Öneri & Şikayet', key: 'suggestion-complaint' },
];
