import { createSlice } from '@reduxjs/toolkit';
import firebase from 'src/lib/firebase';

const initialState = {
  notifications: [],
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    getNotifications(state, action) {
      state.notifications = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export const getNotifications = userId => async dispatch => {
  const notificationsRef = await firebase
    .firestore()
    .collection('users')
    .doc(userId)
    .collection('notifications')
    .orderBy('createdAt', 'desc')
    .get();

  dispatch(
    slice.actions.getNotifications(
      notificationsRef.docs.map(doc =>
        Object.assign({ id: doc.id }, doc.data()),
      ),
    ),
  );
};

export const markAllNotificationsAsRead = userId => async dispatch => {
  const notificationsRef = await firebase
    .firestore()
    .collection('users')
    .doc(userId)
    .collection('notifications')
    .where('hasRead', '==', false)
    .get();

  notificationsRef.forEach(async notification => {
    await firebase
      .firestore()
      .doc(`users/${userId}/notifications/${notification.id}`)
      .update({ hasRead: true });
  });

  dispatch(getNotifications(userId));
};

export const deleteAllNotifications = userId => async dispatch => {
  const notificationsRef = await firebase
    .firestore()
    .collection('users')
    .doc(userId)
    .collection('notifications')
    .get();

  notificationsRef.forEach(async notification => {
    await firebase
      .firestore()
      .collection('users')
      .doc(userId)
      .collection('notifications')
      .doc(notification.id)
      .delete();

    dispatch(getNotifications());
  });
};

export default slice;
