import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import firebase from 'src/lib/firebase';
import { now } from 'src/utils/date';

const initialState = {
  list: [],
  selected: {
    isLoaded: false,
    user: null,
  },
  isLoading: false,
  error: null,
};

export const GetUsers = createAsyncThunk('post/getUsers', async () => {
  const usersRef = await firebase
    .firestore()
    .collection('users')
    .orderBy('createdAt', 'desc')
    .get();

  return usersRef.docs.map(doc => _.assign({ id: doc.id }, doc.data()));
});

export const GetUserById = createAsyncThunk(
  'post/getUserById',
  async userId => {
    const userRef = await firebase
      .firestore()
      .collection('users')
      .doc(userId)
      .get();

    if (userRef.exists) {
      return _.assign(userRef.data(), { id: userRef.id });
    } else {
      throw new Error('User not found');
    }
  },
);

export const CreateUser = (email, props) => {
  const defaultProps = {
    isNotificationSystemEmail: true,
    isNotificationChatEmail: true,
    isNotificationSystemNotification: true,
    isNotificationChatNotification: true,
  };

  return firebase
    .firestore()
    .collection('users')
    .doc(email)
    .set({
      email: email.toLowerCase(),
      status: 'pending',
      createdAt: now(),
      ...props,
      ...defaultProps,
    });
};

export const GetUser = async email => {
  return await firebase
    .firestore()
    .collection('users')
    .where('email', '==', email.toLowerCase())
    .get()
    .then(snap => {
      if (!snap.empty) {
        return _.assign(snap.docs[0].data(), { id: snap.docs[0].id });
      }
    });
};

export const UpdateUser = async (id, userProps) => {
  return await firebase
    .firestore()
    .collection('users')
    .doc(id)
    .update(userProps);
};

const slice = createSlice({
  name: 'game',
  initialState,
  extraReducers: {
    [GetUsers.fulfilled]: (state, action) => {
      state.list = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    [GetUsers.pending]: (state, action) => {
      state.list = [];
      state.isLoading = true;
      state.error = null;
    },
    [GetUsers.rejected]: (state, action) => {
      state.list = [];
      state.error = action.error;
      state.isLoading = false;
    },
    [GetUserById.fulfilled]: (state, action) => {
      state.selected.isLoaded = true;
      state.selected.user = action.payload;
      state.error = null;
    },
    [GetUserById.pending]: (state, action) => {
      state.selected.isLoaded = false;
      state.selected.user = null;
      state.error = null;
    },
    [GetUserById.rejected]: (state, action) => {
      state.selected.isLoaded = false;
      state.selected.user = null;
      state.error = action.error;
    },
  },
});

export const reducer = slice.reducer;

export const RemoveUser = userId => async dispatch => {
  await firebase
    .firestore()
    .collection('users')
    .doc(userId)
    .delete();
};

export const AsyncUpdateUser = (userId, props) => async dispatch => {
  await firebase
    .firestore()
    .collection('users')
    .doc(userId)
    .update(props);
};
