import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.palette,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    '& dt': {
      marginTop: theme.spacing(2),
    },
  },
}));

const FAQS = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Typography variant="h1" color="textPrimary">
          Frequently asked questions
        </Typography>
        <Box my={3}>
          <Divider />
        </Box>
        <Grid container spacing={3} component="dl">
          <Grid item xs={12} md={6}>
            <Typography variant="overline" color="secondary">
              Game
            </Typography>
            <Box mt={6}>
              <dt>
                <Typography variant="h4" color="textPrimary">
                  Odio sed tortor euismod congue vehicula magnis non varius
                  fames ultrices?
                </Typography>
              </dt>
              <dd>
                <Typography variant="body1" color="textSecondary">
                  Adipiscing pretium dictumst faucibus tempor amet maecenas
                  magna per, sem nibh augue libero tellus sed imperdiet, id
                  pharetra platea auctor vivamus nulla lorem.
                </Typography>
              </dd>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="overline" color="secondary">
              Subscription
            </Typography>
            <Box mt={6}>
              <dt>
                <Typography variant="h4" color="textPrimary">
                  Justo maecenas neque suspendisse malesuada lacus libero
                  curabitur habitasse dictumst?
                </Typography>
              </dt>
              <dd>
                <Typography variant="body1" color="textSecondary">
                  Mollis laoreet velit leo penatibus ultrices senectus non,
                  lacinia pellentesque montes nisi ante nunc tempus sit, ac
                  mauris natoque magnis cubilia lorem placerat, scelerisque
                  praesent consectetur vivamus curae odio.
                </Typography>
              </dd>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

FAQS.propTypes = {
  className: PropTypes.string,
};

export default FAQS;
