import React, { Fragment, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import LoadingScreen from 'src/components/LoadingScreen';
import ChatLayout from 'src/layouts/ChatLayout';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/HomeView';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/LoginView')),
  },
  {
    exact: true,
    guard: ChatLayout,
    path: ['/chat/:ticketId'],
    component: lazy(() => import('src/views/UserReplyView')),
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/AccountView')),
      },
      {
        exact: true,
        path: '/app/overview/dashboard',
        component: lazy(() => import('src/views/Dashboard')),
      },
      {
        exact: true,
        path: '/app/overview',
        component: () => <Redirect to="/app/overview/dashboard" />,
      },
      {
        exact: true,
        path: [
          '/app/help/chat',
          '/app/help/chat/:ticketId',
          '/app/help/chat/:ticketId/tech',
        ],
        component: lazy(() => import('src/views/ChatView')),
      },
      {
        exact: true,
        path: '/app/help/reporting',
        component: lazy(() => import('src/views/ReportingView')),
      },
      {
        exact: true,
        path: '/app/admin/users',
        component: lazy(() => import('src/views/users/UserListView')),
      },
      {
        exact: true,
        path: ['/app/admin/users/create', '/app/admin/users/:userId'],
        component: lazy(() => import('src/views/users/UserFormView')),
      },
      {
        exact: true,
        path: '/app/admin/games',
        component: lazy(() => import('src/views/games/GameListView')),
      },
      {
        exact: true,
        path: ['/app/admin/games/create', '/app/admin/games/:gameId'],
        component: lazy(() => import('src/views/games/GameFormView')),
      },
      {
        exact: true,
        path: '/app/admin',
        component: () => <Redirect to="/app/admin/users" />,
      },
      {
        exact: true,
        path: '/app/help',
        component: () => <Redirect to="/app/help/chat" />,
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/help/chat" />,
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    path: '/docs',
    layout: DocsLayout,
    routes: [
      {
        exact: true,
        path: '/docs',
        component: () => <Redirect to="/docs/welcome" />,
      },
      {
        exact: true,
        path: '/docs/welcome',
        component: lazy(() => import('src/views/docs/WelcomeView')),
      },
      {
        exact: true,
        path: '/docs/ticket-submission',
        component: lazy(() => import('src/views/docs/TicketSubmissionView')),
      },
      {
        exact: true,
        path: '/docs/changelog',
        component: lazy(() => import('src/views/docs/ChangelogView')),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: HomeView,
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

export default routes;
