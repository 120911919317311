import { combineReducers } from '@reduxjs/toolkit';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as notificationReducer } from 'src/slices/notification';
import { reducer as gameReducer } from 'src/slices/game';
import { reducer as userReducer } from 'src/slices/user';
import { reducer as reportingReducer } from 'src/slices/reporting';
import { reducer as dashboardReducer } from 'src/slices/dashboard';

const rootReducer = combineReducers({
  chat: chatReducer,
  form: formReducer,
  notifications: notificationReducer,
  games: gameReducer,
  users: userReducer,
  reporting: reportingReducer,
  dashboard: dashboardReducer,
});

export default rootReducer;
