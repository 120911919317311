import _ from 'lodash';
import React, { createContext, useState } from 'react';

const defaultSettings = {
  logo: '',
  isLoaded: false,
};

const TopBarContext = createContext({
  settings: defaultSettings,
  saveSettings: () => {},
});

export const TopBarProvider = ({ settings, children }) => {
  const [currentSettings, setCurrentSettings] = useState(
    settings || defaultSettings,
  );

  const handleSaveSettings = (update = {}) => {
    const mergedSettings = _.merge({}, currentSettings, update);

    setCurrentSettings(mergedSettings);
  };

  return (
    <TopBarContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings,
      }}
    >
      {children}
    </TopBarContext.Provider>
  );
};

export const TopBarConsumer = TopBarContext.Consumer;

export default TopBarContext;
