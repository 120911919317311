import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import firebase from 'src/lib/firebase';
import objFromArray from 'src/utils/objFromArray';

const initialState = {
  activeTicketId: null,
  isSupportThreadMode: true, // or dev thread mode
  thread: {
    ticket: {},
    messages: [],
    techMessages: [],
  },
  devTeams: {
    byId: {},
    allIds: [],
  },
  tickets: {
    byId: {},
    allIds: [],
  },
  isLoaded: false,
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    getTickets(state, action) {
      state.tickets.byId = objFromArray(action.payload);
      state.tickets.allIds = Object.keys(state.tickets.byId);
    },
    getThread(state, action) {
      const { ticket, messages, techMessages } = action.payload;

      state.thread.ticket = ticket;
      state.thread.messages = messages;
      state.thread.techMessages = techMessages;
      state.activeTicketId = ticket.id;
    },
    markTicketAsSeen(state, action) {
      const { ticketId } = action.payload;
      const ticket = state.tickets.byId[ticketId];

      if (ticket) {
        ticket.hasRead = true;
      }
    },
    markTicketAsUnread(state, action) {
      const { ticketId } = action.payload;
      const ticket = state.tickets.byId[ticketId];

      if (ticket) {
        ticket.hasRead = false;
      }
    },
    resolveTicket(state, action) {
      const { ticketId, status } = action.payload;
      const ticket = state.tickets.byId[ticketId];

      if (ticket) {
        ticket.isResolved = status;
      }
    },
    archiveTicket(state, action) {
      const { ticketId, status } = action.payload;
      const ticket = state.tickets.byId[ticketId];

      if (ticket) {
        ticket.isArchived = status;
      }
    },
    updateTicketTags(state, action) {
      const { ticketId, tags } = action.payload;
      const ticket = state.thread.ticket;

      if (ticket.id === ticketId) {
        state.thread.ticket.tags = tags;
      }
    },
    toggleThreadMode(state, action) {
      state.isSupportThreadMode = action.payload;
    },
    resetActiveThread(state) {
      state.activeTicketId = null;
    },
    startLoad(state) {
      state.isLoaded = false;
    },
    endLoad(state) {
      state.isLoaded = true;
    },
  },
});

export const reducer = slice.reducer;

export const getTickets = ({
  resolveStatus = false,
  archiveStatus = false,
  games = [],
}) => async dispatch => {
  dispatch(slice.actions.startLoad());

  try {
    let ticketsQuery = firebase
      .firestore()
      .collection('tickets')
      .where('isArchived', '==', archiveStatus)
      .where('isResolved', '==', resolveStatus);

    if (games.length > 0) {
      ticketsQuery = ticketsQuery.where(
        'game',
        'in',
        games.map(g => g.name),
      );
    }

    const ticketsRef = await ticketsQuery.orderBy('updatedAt', 'desc').get();

    dispatch(
      slice.actions.getTickets(
        ticketsRef.docs.map(doc => Object.assign({ id: doc.id }, doc.data())),
      ),
    );
  } catch (err) {
    console.error(err);
  }

  dispatch(slice.actions.endLoad());
};

export const getThread = ticketId => async dispatch => {
  const ticketRef = await firebase
    .firestore()
    .collection('tickets')
    .doc(ticketId)
    .get();

  const threadRef = await firebase
    .firestore()
    .collection('tickets')
    .doc(ticketId)
    .collection('messages')
    .orderBy('createdAt', 'asc')
    .get();

  const techThreadRef = await firebase
    .firestore()
    .collection('tickets')
    .doc(ticketId)
    .collection('tech-messages')
    .orderBy('createdAt', 'asc')
    .get();

  dispatch(
    slice.actions.getThread({
      ticket: _.assign(ticketRef.data(), { id: ticketRef.id }),
      messages: threadRef.docs.map(doc =>
        Object.assign({ id: doc.id }, doc.data()),
      ),
      techMessages: techThreadRef.docs.map(doc =>
        Object.assign({ id: doc.id }, doc.data()),
      ),
    }),
  );
};

export const markTicketAsSeen = ticketId => async dispatch => {
  await firebase
    .firestore()
    .collection('tickets')
    .doc(ticketId)
    .update({ hasRead: true });

  dispatch(slice.actions.markTicketAsSeen({ ticketId }));
};

export const markTicketAsUnread = ticketId => async dispatch => {
  await firebase
    .firestore()
    .collection('tickets')
    .doc(ticketId)
    .update({ hasRead: false });

  dispatch(slice.actions.markTicketAsUnread({ ticketId }));
};

export const resolveTicket = (ticketId, user, status) => async dispatch => {
  await firebase
    .firestore()
    .collection('tickets')
    .doc(ticketId)
    .update({
      hasRead: true,
      unreadCount: 0,
      isResolved: status,
      resolvedBy: user,
    });

  dispatch(slice.actions.resolveTicket({ ticketId, status }));
};

export const archiveTicket = (ticketId, user, status) => async dispatch => {
  await firebase
    .firestore()
    .collection('tickets')
    .doc(ticketId)
    .update({
      hasRead: true,
      unreadCount: 0,
      isArchived: status,
      archivedBy: user,
    });

  dispatch(slice.actions.archiveTicket({ ticketId, status }));
};

export const updateTicketTags = (ticketId, tags) => async dispatch => {
  await firebase
    .firestore()
    .collection('tickets')
    .doc(ticketId)
    .update({
      tags,
    });

  dispatch(slice.actions.updateTicketTags({ ticketId, tags }));
};

export const setIsSupportThreadMode = status => dispatch => {
  dispatch(slice.actions.toggleThreadMode(status));
};

export const resetActiveThread = () => dispatch => {
  dispatch(slice.actions.resetActiveThread());
};

export default slice;
