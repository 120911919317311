import moment from 'moment';

const DATEFORMAT = 'DD/MM/yyyy';

const now = () => {
  return moment.utc().valueOf();
};

const timestamp = date => {
  date = moment(date).format(DATEFORMAT);
  return moment(date).format('X'); // returns as timestamp
};

const formatMilis = milis =>
  moment(parseInt(milis)).format('HH:mm:ss DD/MM/YYYY');

export { now, timestamp, DATEFORMAT, formatMilis };
