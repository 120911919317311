/* eslint-disable no-use-before-define */
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  makeStyles,
  Typography,
} from '@material-ui/core';
import AssessmentIcon from '@material-ui/icons/Assessment';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {
  BarChart as BarChartIcon,
  MessageCircle as MessageCircleIcon,
  Users as UsersIcon,
} from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import { getRoleLabel } from 'src/views/users/constants';
import NavItem from './NavItem';

const sections = [
  {
    subheader: 'Overview',
    protected: false,
    items: [
      {
        title: 'Dashboard',
        icon: BarChartIcon,
        href: '/app/overview/dashboard',
      },
    ],
  },
  {
    subheader: 'Help Center',
    protected: false,
    items: [
      {
        title: 'Chat',
        icon: MessageCircleIcon,
        href: '/app/help/chat',
      },
      {
        title: 'Reporting',
        icon: AssessmentIcon,
        href: '/app/help/reporting',
      },
    ],
  },
  {
    subheader: 'Admin',
    protected: true,
    items: [
      {
        title: 'Users',
        icon: UsersIcon,
        href: '/app/admin/users',
      },
      {
        title: 'Games',
        icon: SportsEsportsIcon,
        href: '/app/admin/games',
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        [],
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>,
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />,
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/account">
              <Avatar alt="User" className={classes.avatar} src={user.avatar} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.name}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {`Role: ${getRoleLabel(user.role)}`}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections
            .filter(section => !(user.role !== 'admin' && section.protected))
            .map(section => (
              <List
                key={section.subheader}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname,
                })}
              </List>
            ))}
        </Box>
        {['admin', 'tech'].includes(user.role) && (
          <>
            <Divider />
            <Box p={2}>
              <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
                <Typography variant="h6" color="textPrimary">
                  For the integration
                </Typography>
                <Link
                  variant="subtitle1"
                  color="secondary"
                  component={RouterLink}
                  to="/docs"
                >
                  Check our docs
                </Link>
              </Box>
            </Box>
          </>
        )}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
