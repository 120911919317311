import React from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Hero from './Hero';
import FAQS from './FAQS';

const useStyles = makeStyles(() => ({
  root: {},
}));

const HomeView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Home">
      <Hero />
      <FAQS />
    </Page>
  );
};

export default HomeView;
