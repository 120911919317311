import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import firebase from 'src/lib/firebase';

const initialState = {
  error: null,
  isLoaded: false,
  list: [],
  count: 0,
  issues: {
    isLoaded: false,
    list: [],
  },
};

const slice = createSlice({
  name: 'reporting',
  initialState,
  reducers: {
    get(state, action) {
      state.selected = action.payload;
    },
    all(state, action) {
      state.list = action.payload.list;
      state.count = action.payload.count;
    },
    page(state, action) {
      state.list = action.payload;
    },
    reset(state, action) {
      state.selected = null;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    startLoad(state, action) {
      state.isLoaded = false;
    },
    endLoad(state, action) {
      state.isLoaded = true;
    },
    startLoadIssues(state, action) {
      state.issues.isLoaded = false;
    },
    endLoadIssues(state, action) {
      state.issues.isLoaded = true;
    },
    issues(state, action) {
      state.issues.list = action.payload;
    },
    resetIssues(state, action) {
      state.issues.list = [];
    },
  },
});

export const reducer = slice.reducer;

export const all = (limit = 100) => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    let ticketsQueryRef = firebase.firestore().collection('tickets');

    const pageTicketsRef = await ticketsQueryRef
      .orderBy('updatedAt', 'desc')
      .limit(limit)
      .get();

    const ticketsRef = await ticketsQueryRef.get();

    dispatch(
      slice.actions.all({
        list: pageTicketsRef.docs.map(doc =>
          _.merge({ id: doc.id }, doc.data()),
        ),
        count: ticketsRef.size,
      }),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(slice.actions.endLoad());
};

export const getPage = (startAfter, limit) => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    let ticketsQueryRef = firebase
      .firestore()
      .collection('tickets')
      .orderBy('updatedAt', 'desc');

    if (startAfter) {
      ticketsQueryRef = ticketsQueryRef.startAfter(startAfter.updatedAt);
    }

    const ticketsRef = await ticketsQueryRef.limit(limit).get();

    dispatch(
      slice.actions.page(
        ticketsRef.docs.map(doc => _.merge({ id: doc.id }, doc.data())),
      ),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(slice.actions.endLoad());
};

export const getIssues = ticketId => async dispatch => {
  dispatch(slice.actions.startLoadIssues());
  try {
    let ticketsRef = await firebase
      .firestore()
      .collection('tickets')
      .doc(ticketId)
      .collection('messages')
      .where('isIssue', '==', true)
      .orderBy('createdAt', 'desc')
      .get();

    dispatch(
      slice.actions.issues(
        ticketsRef.docs.map(doc => _.merge({ id: doc.id }, doc.data())),
      ),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(slice.actions.endLoadIssues());
};

export const resetIssues = () => dispatch => {
  dispatch(slice.actions.resetIssues());
};

export default slice;
