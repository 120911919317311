import { createSlice } from '@reduxjs/toolkit';
import firebase from 'src/lib/firebase';

const initialState = {
  error: null,
  solved: {
    isLoaded: false,
    count: 0,
  },
  unsolved: {
    isLoaded: false,
    count: 0,
  },
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setSolved(state, action) {
      state.solved.isLoaded = true;
      state.solved.count = action.payload;
    },
    setUnsolved(state, action) {
      state.unsolved.isLoaded = true;
      state.unsolved.count = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export const fetchSolved = () => async dispatch => {
  try {
    const solvedRef = await firebase
      .firestore()
      .collection('tickets')
      .where('isResolved', '==', true)
      .orderBy('updatedAt', 'desc')
      .get();

    dispatch(slice.actions.setSolved(solvedRef.docs.length));
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const fetchUnsolved = () => async dispatch => {
  try {
    const unsolvedRef = await firebase
      .firestore()
      .collection('tickets')
      .where('isResolved', '==', false)
      .orderBy('updatedAt', 'desc')
      .get();

    dispatch(slice.actions.setUnsolved(unsolvedRef.docs.length));
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export default slice;
