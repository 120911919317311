import { useSnackbar } from 'notistack';
import React, { createContext, useEffect, useReducer } from 'react';
import SplashScreen from 'src/components/SplashScreen';
import firebase from 'src/lib/firebase';
import { CreateUser, GetUser, UpdateUser } from 'src/slices/user';
import { COMPANY } from 'src/config';

const actionCodeSettings = {
  url: 'http://localhost:3000/app/',
  handleCodeInApp: true,
};

class AuthError extends Error {
  constructor(message) {
    super(message);
    this.name = 'AuthError';
  }
}

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGED': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  sendSignInLinkToEmail: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { enqueueSnackbar } = useSnackbar();

  const sendSignInLinkToEmail = (email, props) => {
    return firebase
      .auth()
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(CreateUser(email, props));
  };

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  };

  const logout = () => {
    return firebase.auth().signOut();
  };

  const checkUser = async user => {
    try {
      let authUser = await GetUser(user.email);

      if (!authUser && COMPANY.ADMINS.includes(user.email)) {
        await CreateUser(user.email, {
          role: 'admin',
          status: 'active',
          avatar: user.photoURL,
          name: user.displayName || user.email,
        });

        authUser = await GetUser(user.email);
      } else if (
        !authUser ||
        !['active', 'pending'].includes(authUser.status)
      ) {
        throw new AuthError('Only authorized email');
      }

      const syncUserProps = {
        avatar: user.photoURL,
        name: user.displayName || user.email,
        status: 'active',
      };

      await UpdateUser(authUser.id, syncUserProps);

      dispatch({
        type: 'AUTH_STATE_CHANGED',
        payload: {
          isAuthenticated: true,
          user: {
            id: authUser.id,
            avatar: syncUserProps.avatar,
            email: authUser.email,
            name: syncUserProps.name,
            role: authUser.role,
            games: authUser.games,
          },
        },
      });
    } catch (err) {
      if (err instanceof AuthError) {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      }

      dispatch({
        type: 'AUTH_STATE_CHANGED',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  };

  useEffect(() => {
    return firebase.auth().onAuthStateChanged(checkUser);
    // eslint-disable-next-line
  }, [dispatch]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        sendSignInLinkToEmail,
        signInWithGoogle,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
