import _ from 'lodash';
export const roleOptions = [
  {
    value: 'admin',
    label: 'Administrator',
  },
  {
    value: 'support',
    label: 'Support',
  },
  {
    value: 'tech',
    label: 'Tech',
  },
];

export const getRoleLabel = role => {
  return _.find(roleOptions, { value: role })?.label;
};
